import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      icon: "mdi-home",
      shown: true,
    },
  },
  {
    path: "/servers",
    name: "Servers",
    component: () => import("@/views/ServersView.vue"),
    meta: {
      shown: true,
      icon: "mdi-server",
      permission: "servers:view",
    },
  },
  {
    path: "/websites",
    name: "Web Statistics",
    component: () => import("@/views/WebStatisticsView.vue"),
    meta: {
      shown: true,
      icon: "mdi-chart-bar",
      permission: "websites:view",
    },
    children: [
      {
        path: "/websites/:id/statistics",
        name: "WebsiteStatistics",
        component: () =>
          import("@/components/Statistics/WebsiteStatistics.vue"),
        meta: {
          shown: false,
          permission: "websites:view",
        },
      },
    ],
  },
  {
    path: "/customers",
    name: "Customers",
    component: () => import("@/views/CustomersView.vue"),
    meta: {
      shown: true,
      icon: "mdi-account-group",
      permission: "clients.view",
    },
  },
  {
    path: "/unauthorized",
    name: "MissingPermissions",
    component: () => import("@/views/MissingPermissions.vue"),
    meta: {
      shown: false,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.meta.permission &&
    !Vue.prototype.$checkPermission(to.meta.permission)
  ) {
    next({ name: "MissingPermissions" });
  } else {
    document.title = `Honex - ${to.name}`;
    next();
  }
});

export default router;

// Export routes

export { routes };
