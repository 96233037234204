import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { getUser } from "./plugins/userDetail";
import checkPermission from "./plugins/checkPermission";
import request from "./plugins/request";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(getUser);
Vue.use(checkPermission);
Vue.use(request);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
